import React, {Component} from 'react';
import Layout from "../components/Layout/Layout";
import LayoutWithMenu from "../components/Layout/LayoutWithMenu";
import {graphql} from "gatsby";
import ProjectList from "../components/ProjectList/ProjectList";
import {Helmet} from "react-helmet";

export default class Intervetions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        // this.color = this.props.data.colors.edges[Math.floor(Math.random() * this.props.data.colors.edges.length)].node.frontmatter.color
        this.projects = this.props.data.projects.edges
    }


    render() {
        this.color = this.props.data.colors.edges[Math.floor(Math.random() * this.props.data.colors.edges.length)].node.frontmatter.color
        return (
            <Layout isIndex={true} background={this.color}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>↪ cocktail — atelier ∆ Bayonne</title>
                    <meta name="description" content="Lucie Lafitte & Chloé Serieys ⇆ Atelier multidisciplinaire / design graphique, fabrication, pédagogie, édition, coordination." />
                </Helmet>
                <LayoutWithMenu path={this.props.location.pathname}>
                    <ProjectList data={this.projects}/>
                </LayoutWithMenu>
            </Layout>
        );
    }
}

export const pageQuery = graphql`
  query InterventionsQuery {
    colors: allMarkdownRemark(filter: {frontmatter: {type: {eq: "color"}}}) {
        edges {
          node {
            frontmatter {
              color
            }
          }
        }
    }
    projects: allMarkdownRemark(filter: {frontmatter: {type: {eq: "project"}, isIntervention: {eq: true}}}, sort: {fields: id, order: DESC}) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              arrowColor
              images {
                publicURL
              }
              title
              tags
            }
          }
        }
    }
  }`